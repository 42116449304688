<template>
  <div v-if="loading" class="loader"></div>
    <div v-else class="chatbox">
      <div class="chatbox__support">
        <div class="chatbox__header">
          <div class="chatbox__image--header"></div>
          <div class="chatbox__content--header">
            <h4 class="chatbox__heading--header">Hero Sir</h4>
            <v-btn class="history-button mr-5" @click="showHistory = !showHistory" style="color: white; background:skyblue; width:10%; font-size:24px; font-weight:bold;">Chat Log</v-btn>
          </div>
        </div>
        <v-dialog v-model="showHistory" height="50%"  @click:outside="showHistory=false" >
          <v-card height="500px" >
        <v-card class="chatbox__messages" id="chat-messages" ref="chatMessages" style="overflow-y:auto;">
          <v-card class="chatbox__loading__message">
            <div v-if="loading" class="loading__message messages__item messages__item--visitor">loading ...</div>
          </v-card>
          <div v-for="message in messages" :key="message.id">
          <div  v-if="message.type!=='bot'" class=" messages__item messages__item--operator "
          > <v-row class="mt-1 mb-1">
          <button @click="readText(message)">
            
           <v-icon class="ml-2 align-self-start">M8,5.14V19.14L19,12.14L8,5.14Z</v-icon> 
          </button><div style="max-width:95%" class="pr-2">{{ message.text }}</div></v-row>
          
          </div>
          <div  v-if="message.type==='bot'" class="messages__item messages__item--vistor "
          > <v-row class="mt-1 mb-1">
          <button @click="readText(message)">
            
           <v-icon class="ml-2 align-self-start">M8,5.14V19.14L19,12.14L8,5.14Z</v-icon> 
          </button> 
          <!-- <div style="max-width:95%" class="pr-2">{{ message.text }}</div> -->
          <v-container v-if="message.text.trim() !== ''" v-html="renderLatexString(message.text.replace('$',''))" style="font-size: 14px; width:90%; word-wrap: break-word; overflow-x:auto;"></v-container>
        </v-row>
          
          </div>
        </div>
        </v-card>
          <!-- <div>
            <textarea v-model="text" placeholder="Enter text to read out"></textarea>
            <button @click="readText">Read Text</button>
        </div> -->
         
      </v-card>
        </v-dialog>

        <v-card class="d-flex flex-row align-center" style="height: 80%;">
          <v-icon @click="previousNote" style="font-size:60px;width:5%;">M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z</v-icon> 
          <v-col ref="katexContainer" class="d-flex justify-center" style="height: 100%;width:80%; overflow: hidden;flex-direction: column;">
            <v-card style="width: 100%; height: 60%; display: flex; flex-direction: column; align-items:center;">
              <div v-if="currentNote" style="overflow-y: auto; flex-grow: 1; width: 100%; ">
                <h1>Step: {{ noteCardIndex + 1 }}</h1>
                <div v-for="(part, index) in currentNote" :key="index" style="width:100%">
                  <v-container v-if="part.trim() !== ''" v-html="renderToString(part.replace('$',''))" style="font-size: 30px;"></v-container>
                </div>
              </div>
            </v-card>
            <br>
             <!-- <v-container v-if="noteCards[noteCardIndex]" style="width: 100%; height: 30%; display: flex; flex-direction: column;overflow-y: auto; font-size:24px;"  v-html="renderLatexString('Subtitles: '+ noteCards[noteCardIndex].text)"></v-container> -->
             <v-container v-if="noteCards[noteCardIndex]" style="width: 100%; height: 30%; display: flex; flex-direction: column;overflow-y: auto; font-size:24px;"  ><p>Subtitles: {{noteCards[noteCardIndex].text}}</p></v-container>
            </v-col>
            <v-icon @click="nextNote" style="font-size:60px; width:5%;">M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z</v-icon> 


        </v-card>
        <div class="default__questions">
          <button class="default__question__button" @click="sendMessage('Tell me more about this question')">Tell me more about this question</button>
          <button class="default__question__button" @click="sendMessage('Give me some hints')">Give me some hints</button>
          
        </div>
  
        <div class="chatbox__footer">
          <audio  ref="audio" :src="audioUrl" controls ></audio>
          <button @click="startRecording" class="mr-3 ml-3" style="color: white; background:skyblue; width:10%; font-size:24px; border-radius:7px;">Start Recording</button>
          <button @click="stopRecording" class="mr-3 ml-3" :disabled="!isRecording" style="color: white; background:skyblue; width:10%; font-size:24px;border-radius:7px;">Stop Recording</button>
          
          <textarea v-model="userMessage" style="color:white;" placeholder="Write a message..." class="chat-input"></textarea>
          <button @click="sendMessage(userMessage)" class="chatbox__send--footer send__button">Send</button>
        </div>
      </div>
      <div class="chatbox__button">
        <!-- <button @click="toggleChatbox"><img src="@/assets/HeroSir.svg" /></button> -->
      </div>
    </div>
  </template>
  
  <script >
  import katex from 'katex';
  import 'katex/dist/katex.min.css';
  import axios from '../axios';
  import '../css/chatbot.css';
  import { mdiPlay,mdiArrowRight,mdiArrowLeft } from '@mdi/js'
 
  export default {
    props:{
    //  token:{
    //   type:String,
    //   required:true
    //   },
     question_id:{
      type:String,
      required:true
      }
    },
    data() {
      return {
        messages: [],
        userMessage: '',
        loading: true,
        mediaRecorder: null,
        audioChunks: [],
        audioUrl: null,
        isRecording: false,
        text: '',
        currentNote:null,
        showHistory:false,
        latexParts:[],
        noteCards:null,
        noteCardIndex:null,
        numNotes:0,
        userInteracted:false,
        audioReady:false,
       
      };
    },
    watch:{
    
    noteCardIndex(val){
      if (val!=null){this.processLatex(this.noteCards[val].item);
      if (!this.noteCards[val].audioData){
        this.generateScriptSpeech(this.noteCards[val])
      }else{
        this.audioUrl=this.noteCards[val].audioData
        this.$refs.audio.play()
      }

    }},
    messages: {
      handler() {
        // Scroll to bottom when messages are updated
        this.scrollToBottom();
      },
      deep: true
    }
  },
    computed: {

  },
  async beforeMount() {
    await this.initializeData();
    window.addEventListener('click', this.onUserInteraction);
      window.addEventListener('keydown', this.onUserInteraction);
  },
  async mounted() {
    console.log(mdiPlay)
    console.log(mdiArrowLeft)
    console.log(mdiArrowRight)
  },
  methods: {
    onUserInteraction(){
      if(!this.userInteracted){this.userInteracted=true
    const audio= this.$refs.audio
    if (this.audioReady)
    {setTimeout(function () {      
      if(audio)  audio.play();
            }, 150);}
      window.removeEventListener('click', this.onUserInteraction);
      window.removeEventListener('keydown', this.onUserInteraction);}
    },
    scrollToBottom() {
      var container = this.$el.querySelector("#chat-messages");
      if(container){container.scrollTop = container.scrollHeight;}
    }
      ,
    renderLatexString(text){
      console.log('prelatex render', text);
      const cleaned = text.replace(/\\\[/g, '##[').replace(/\\\]/g, ']##').replace(/\\\(/g, '##[').replace(/\\\)/g, ']##');
      const parts = cleaned.split('##')
      let output=''
      parts.forEach(part => {
          // Check if the part is a delimiter or mathematical symbol; adjust the logic as necessary
          if (
            part.includes('{')||part.includes('}')||
            part.includes('(')||part.includes(')')||
            part.includes('[')||part.includes(']')
        ) {
              // Leave mathematical and delimiters unchanged
              output+= part.replace(/\[\]/g,'');
          } else {
              // Wrap non-mathematical parts with \text{}
              // Ensure the part is not empty before wrapping
              output+= part.trim() ? `\\text{${part}}` : part;
          }
        })
          return katex.renderToString(output, { throwOnError: false });

    },
    async initializeData() {
      try {
        const response = await axios.post(
          '/api/herosir/get_script',
          { 
            question_id: this.question_id
          },
          {
            // headers: {
            //   Authorization: `Bearer ${this.token}`
            // }
          }
        );
        const answer = response.data.response;
        console.log('res', answer);
        
        // this.latexParts.push('\\frac{(2 x^{4})^{3}}{2 x^{5}} =')
        this.noteCards = answer.map((item, index) => ({
            index: index,
            item: item.latex.replace(/\\\\left/g, '')
                      .replace(/\\\\right/g, '')
                      .replace(/\\/g, '\\').replace(/\\\[/g, '$$').replace(/\\\]/g, '$$').replace(/\\\(/g,'$').replace(/\\\)/g, '$'),
            text:item.audio,
            audioData:null
                    }));
                console.log(this.noteCards)
        this.noteCardIndex=0
        this.numNotes=this.noteCards.length
       this.loading=false

        
      } catch (error) {
        console.error('Error fetching script:', error);
      }
    },
    processLatex(text) {
      const segments = text.split(/\n\n|\$\$/);
  this.currentNote = [];

  segments.forEach(segment => {
    const temp = segment.split('$');
    let out = '';

    temp.forEach((part,index) => {
      if (index%2==0 && part.trim()!='' && !part.includes('{') && !part.includes('\\')&&!/[+=^]/.test(part)) {
        out += `\\text{ ${part.trim()} } `;
      } else {
        out += ' '+part+' ';
      }
    });

    this.currentNote.push(out.trim());} 
  );
  this.currentNote = this.currentNote.filter(note => note.trim() !== '');
  console.log('currentNOTE',this.currentNote)
},
      
//       for (let j = 0; j < combined.length; ++j) {
//     if (combined[j].value === ':') {
//         combined.splice(j, 1); 
//         if (j > 0) {
//             combined[j - 1].value += ':';
//         }
//         j--; 
//     }
// }



   
    renderToString(part) {
      // part=part.replace('[','').replace(']','').replace(/\\/g, '\\\\')
      console.log(part)
      return katex.renderToString(part, { throwOnError: false });
    },
      async sendMessage(message) {
        if (!message.trim()) return;
        this.showHistory=true
        this.messages.push({ text: message, type: 'user' });
        this.userMessage = '';
        
        try {

         
          const response = await axios.post(
              '/api/herosir/',
              { 
                  message:message,
                  question_id:this.question_id
              },
              {
                  // headers: {
                  //     Authorization: `Bearer ${this.token}`
                  // }
              }
          );
          this.messages.push({ text: response.data.response.answer, type: 'bot' });
        } catch (error) {
          console.error('Error sending message:', error);
        } 
       
      },
      async readText(message) {

        console.log('update Check ------------------')
        this.audioReady=false
        const audio=this.$refs.audio
        if(audio){audio.pause()}
          const response = await axios.post(
            '/api/herosir/generate_speech',
            { 
              message: message.text
            },
            {
              // headers: {
              //   Authorization: `Bearer ${this.token}`
              // },
              responseType: 'arraybuffer'
            }
          );
          try {
           console.log("audio response here", response)
            const arrayBuffer = response.data
          const blob = new Blob([arrayBuffer], { type: 'audio/mp3' }); // Adjust MIME type as needed
          
          
          this.audioUrl = URL.createObjectURL(blob);
          this.audioReady=true
          const audio = this.$refs.audio
         
          setTimeout(function () {    
            if(audio) audio.play();
            }, 150);
          } catch (error) {
            console.error('Error fetching or playing audio:', error);
          }
   
         
    },
      async generateScriptSpeech(message) {
        this.audioReady=false
        const audio=this.$refs.audio
        if(audio){audio.pause()}

          const response = await axios.post(
            '/api/herosir/generate_speech',
            { 
              message: message.text
            },
            {
              responseType: 'arraybuffer'
            }
          );
          try {
            const arrayBuffer = response.data
          const blob = new Blob([arrayBuffer], { type: 'audio/mp3' }); // Adjust MIME type as needed
          this.noteCards[this.noteCardIndex].audioData=URL.createObjectURL(blob);
          this.audioUrl= this.noteCards[this.noteCardIndex].audioData
          this.audioReady=true

          setTimeout(function () {      
            if(audio) audio.play();
            }, 150);
          } catch (error) {
            console.error('Error fetching or playing audio:', error);
          }
       
          return 
         
    },
      nextNote(){
       if(this.noteCardIndex+1<this.numNotes) {this.noteCardIndex+=1;
       this.$refs.audio.pause()}
       else return
      },
      previousNote(){
       if (this.noteCardIndex>0) {this.noteCardIndex-=1;
       this.$refs.audio.pause()}
       else return
      },
      
      async startRecording() {
      try {
        // Request access to the user's microphone
        console.log(navigator)
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        // Initialize the MediaRecorder with the audio stream
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        // Collect audio data when available
        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        // Handle the stop event
        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
         
          const formData = new FormData();
          formData.append('file', audioBlob, 'recording.wav');
          const response = await axios.post(
            '/api/herosir/generate_text',
            formData,
            {
              // headers: {
              //   Authorization: `Bearer ${this.token}`,
              //   'Content-Type': 'multipart/form-data'
              // },
              responseType: 'text'
            }
          );

          this.sendMessage(response.data)
        };

        // Start recording
        this.mediaRecorder.start();
        this.isRecording = true;
        console.log(this.mediaRecorder)
      } catch (error) {
        console.error('Error accessing the microphone:', error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    }}
  };
  </script>
  
  <style scoped>
  /* Component-specific styles */
  .bot { text-align: right; }
  .user { text-align: left; }
  button {
    display: flex;
    align-items: center;
    background-color: transparent;
  }
  button i {
    background-color: transparent;
    margin-right: 5px;
  }
  .history-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 50px auto; /* Center the loader */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>