<template>
  <div>
    <HeroSir  :question_id="id"/>
  </div>
</template>

<script>
// import MathJaxPlayground from './components/MathJaxPlayground.vue';
import HeroSir from './components/HeroSir.vue';

export default {
  name: 'ParentComponent',
  components: {
    HeroSir
  },
  data(){
  return{
    open:false,
    // token:null,
    id:null
  }
  },
  beforeMount(){
    // this.token=window.location.search.split('&')[0].split('=')[1]
    this.id=window.location.search.split('&')[0].split('=')[1]
    // this.id=window.location.search.split('=')[3]
}};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
