// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify'; // Import the Vuetify plugin
import 'katex/dist/katex.min.css';


const app = createApp(App);

app.use(vuetify);

app.mount('#app');
